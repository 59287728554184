import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import {
  MediaUploader,
  UpsertMedia,
  Home,
  UserList,
  Company,
  EcoSystem,
  Account,
  SiteCopy,
  PlatformAnalytics,
  TeamListing,
  CreateTeam,
  ViewCompanyAssessment,
  ViewEcosystemAssessment,
  CombinedAwarenessAssessment,
  UserCombinedSurveyReport,
  CompanyCompareAssessment,
  EcoSystemCompareAssessment,
  CompanyDateSurveyResult,
  EcosystemDateSurveyResult,
  SurveyCategory,
  Coaches,
  CoachClientList,
  CoachingPlan,
  CoachProfile,
  UpdatePassword,
  UpdateTeam,
  TeamSurveyResult,
  AddTeamSession,
  TeamQuarterAssessmentResult,
  TeamDateRangeAssessmentResult,
  TeamCompareAssessment,
  SessionInformation,
  Clients,
  ClientProfile,
  UserCompareAssessment,
  AddSession,
  UpdateSessionOfUser,
  Upload360Report,
  UploadImpactReport,
  ClientSurveyResult,
  PromoCode,
  UpdatePromoCode,
  EmailSequencing,
  UserEmailsDetails,
  UserPaymentInformation,
  AdminProfile,
  SessionInsights,
  UploadCustomReport,
  GroupJourney,
  DeleteUser,
  LAResult,
  GrowthPlanCall,
  ClientOnboarding,
  Custom360Resuls,
  SubScriptionPayment,
  WisdomAssessmentResult,
  Blog,
  BlogDetail,
} from '../pages'
import Quests from '../pages/Quests'
import { Layout, MediaControlWrapper } from '../components/layout'
import {
  useUserService,
  simple360Url,
  coachRecommendsUrl,
} from '@abroad/components'
import PrivateRoute from './PrivateRoute'
import CreateQuest from '../components/Quests/CreateQuest'
import ViewGrowthPlan from '../components/userGrowthPlan/ViewGrowthPlan'

/**
 * Navigation routes for the app
 *
 * @info [react-router-dom](https://reactrouter.com/web/guides/quick-start)
 */
const AuthenticatedRoutes = () => {
  const { user } = useUserService()

  return (
    <Switch>
      <MediaControlWrapper>
        <PrivateRoute
          path={[
            '/profile/update-password',
            '/admin/groups/:teamId/information',
            '/admin/groups/:teamId/growth-plan',
            '/admin/groups/:teamId/assessments',
            '/admin/groups/:teamId/session-report',
            '/admin/groups/:teamId/circle',
            '/admin/groups/:teamId/session',
            `/admin/groups/:teamId/${coachRecommendsUrl}`,
            '/admin/groups/:teamId/session/:sessionId',
            '/survey/group/:teamId',
            '/user/:userId/comparison_report',
            '/coach/clients/:clientId/session/:sessionId',
            '/coach/clients/:clientId/reports/360',
            '/coach/clients/:clientId/reports/custom',
            '/coach/clients/:clientId/plans/:planId',
            '/coach/clients/:clientId/reports/Impact',
            '/survey/:surveyId/clients/:clientId',
            '/survey/group/:teamId/group_combined_report',
            '/admin/survey/group/:teamId',
            '/survey/compare/group/:teamId',
            '/survey/group-comparison-report-common/:teamId',
            '/coach/clients',
            '/coach/groups',
            '/coach/clients/:clientId/session',
            '/coach/clients/:clientId',
            '/coach/clients/:clientId/overview',
            '/coach/clients/:clientId/analytics',
            '/coach/clients/:clientId/growth-plan',
            '/coach/clients/:clientId/growth-plan/:sowId/:planId/view',
            '/coach/clients/:clientId/growth-plan/:sowId/:planId',
            '/admin/users/:clientId/growth-plan/:sowId/:planId',
            '/coach/clients/:clientId/sessions',
            `/coach/clients/:clientId/${simple360Url}`,
            `/coach/clients/:clientId/${coachRecommendsUrl}`,
            '/admin/client/:clientId/view/client-profile',
            '/coach/clients/:clientId/intentions',
            '/coach/clients/:clientId/plans-payments',
            `/admin/users/:clientId/${coachRecommendsUrl}`,
            '/admin/users/:clientId/findCoaches',
            `/admin/users/:clientId/${simple360Url}`,
            '/admin/users/:clientId/intentions',
            '/admin/users/:clientId/plans-payments',
            '/admin/media',
            '/admin/upsert/:mediaCategory/:catId/:subCatId/:mediaId',
            '/admin/users',
            '/admin/home',
            '/admin/organizations',
            '/admin/networks',
            '/admin/quests',
            '/admin/quests/create-new',
            '/admin/quests/:questId',
            '/admin/quests/:questId/details',
            `/admin/quests/:questId/inquiries`,
            '/admin/quests/:questId/itinerary',
            `/admin/quests/:questId/participants`,
            `/admin/quests/:questId/learning`,
            `/admin/blogs`,
            `/admin/blogs/create-new`,
            `/admin/blogs/:blogId/detail`,
            '/admin/organization/:companyName/users',
            '/admin/organization/:companyName/survey',
            '/admin/organization/:companyName/coach',
            '/admin/organization/:companyName/groups',
            '/admin/network/:ecoSystemName/users',
            '/admin/network/:ecoSystemName/survey',
            '/admin/network/:ecoSystemName/invite-link',
            '/admin/network/:ecoSystemName/welcome-email',
            '/admin/survey/users_combined_report',
            '/admin/survey/organization_comparison_report',
            '/admin/survey/user_comparison_report_common',
            '/admin/survey/network_comparison_report',
            '/admin/survey/network_user_comparison_report_common',
            '/admin/survey/organization/:companyName',
            '/admin/survey/network/:ecoSystemName',
            '/admin/account',
            '/admin/sitecopy/user',
            '/admin/statistics',
            '/admin/groups',
            '/admin/groups/active',
            '/admin/groups/archive',
            '/admin/group',
            '/admin/coaches',
            '/admin/coach/:coachId/clients/:clientId/plans/:planId',
            '/admin/client-onboarding/pending-requests',
            '/admin/client-onboarding/completed-requests',
            '/admin/coaches/:coachId',
            '/admin/coach/:coachId/profile',
            '/admin/users/:clientId',
            '/admin/users/:clientId/overview',
            '/admin/users/:clientId/analytics',
            '/admin/users/:clientId/growth-plan/:sowId/:planId/view',
            '/admin/users/:clientId/sessions',
            '/admin/users/:clientId/update-email',
            '/survey/organization/:companyName',
            '/survey/network/:ecoSystemName',
            '/survey/organization/view/:companyName',
            '/survey/network/view/:ecoSystemName',
            '/survey/category/:category',
            '/admin/promo',
            '/admin/promo/:promoId',
            '/admin/scheduled-emails',
            '/admin/scheduled-emails/user/:userId',
            '/admin/payment',
            '/admin/subscription-payments',
            '/admin/subscription-payments/sows',
            '/admin/subscription-payments/payments',
            '/coach/:coachId/profile',
            '/admin/:coachId/profile',
            '/admin/profile/:adminId',
            '/admin/session-insights',
            '/admin/organization/:companyName/sows',
            '/admin/organization/:companyName/invite-link',
            '/admin/organization/:companyName/objectives',
            '/admin/organization/:companyName/purpose-and-vision',
            '/admin/organization/:companyName/values-and-principles',
            '/admin/organization/:companyName/sow/:sowId/details',
            '/admin/organization/:companyName/sow/:sowId/users',
            '/admin/organization/:companyName/sow/:sowId/sow-groups',
            '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId',
            '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId/users',
            '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId/sessions',
            '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId/coaches',
            `/admin/organization/:companyName/sow/:sowId/sow-group/:teamId/${coachRecommendsUrl}`,
            '/admin/delete-user',
            '/survey/leader/:id/clients/:clientId',
            '/admin/custom360/users/:clientId/results/recipient',
            '/coach/custom360/clients/:clientId/results/recipient',
            '/admin/custom360/users/:clientId/results/question',
            '/coach/custom360/clients/:clientId/results/question',
            '/wisdom/:id/clients/:clientId',
          ]}>
          <Layout>
            <Switch>
              <PrivateRoute
                exact
                path='/profile/update-password'
                component={UpdatePassword}
              />
              <PrivateRoute
                exact
                path={`/admin/groups/:teamId/information`}
                component={UpdateTeam}
              />
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/groups/:teamId/growth-plan`}
                  component={UpdateTeam}
                />
              )}
              <PrivateRoute
                exact
                path={`/admin/groups/:teamId/assessments`}
                component={UpdateTeam}
              />
              <PrivateRoute
                exact
                path={`/admin/groups/:teamId/session-report`}
                component={UpdateTeam}
              />
              <PrivateRoute
                exact
                path={`/admin/groups/:teamId/circle`}
                component={UpdateTeam}
              />
              <PrivateRoute
                exact
                path={`/admin/groups/:teamId/session`}
                component={AddTeamSession}
              />
              <PrivateRoute
                exact
                path={`/admin/groups/:teamId/${coachRecommendsUrl}`}
                component={GroupJourney}
              />
              <PrivateRoute
                exact
                path={`/admin/groups/:teamId/session/:sessionId`}
                component={SessionInformation}
              />
              <PrivateRoute
                exact
                path={`/survey/group/:teamId`}
                component={TeamSurveyResult}
              />
              <PrivateRoute
                exact
                path={`/user/:userId/comparison_report`}
                component={UserCompareAssessment}
              />
              {(user?.isCoach || user?.isAdmin) && (
                <PrivateRoute
                  exact
                  path={`/coach/clients/:clientId/session/:sessionId`}
                  component={UpdateSessionOfUser}
                />
              )}
              <PrivateRoute
                exact
                path={`/coach/clients/:clientId/reports/360`}
                component={Upload360Report}
              />
              <PrivateRoute
                exact
                path={'/coach/clients/:clientId/reports/custom'}
                component={UploadCustomReport}
              />
              <PrivateRoute
                exact
                path={`/coach/clients/:clientId/plans/:planId`}
                component={CoachingPlan}
              />
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={[
                    '/admin/client-onboarding/pending-requests',
                    '/admin/client-onboarding/completed-requests',
                  ]}
                  component={ClientOnboarding}
                />
              )}
              <PrivateRoute
                exact
                path={`/coach/clients/:clientId/reports/Impact`}
                component={UploadImpactReport}
              />
              <PrivateRoute
                exact
                path={`/survey/:surveyId/clients/:clientId`}
                component={ClientSurveyResult}
              />
              <PrivateRoute
                exact
                path={`/survey/group/:teamId/group_combined_report`}
                component={TeamQuarterAssessmentResult}
              />
              <PrivateRoute
                exact
                path={`/admin/survey/group/:teamId`}
                component={TeamDateRangeAssessmentResult}
              />
              <PrivateRoute
                exact
                path={`/survey/compare/group/:teamId`}
                component={TeamCompareAssessment}
              />
              <PrivateRoute
                exact
                path={`/survey/group-comparison-report-common/:teamId`}
                component={() => (
                  <TeamCompareAssessment apiFor='common-users' />
                )}
              />
              {user?.isCoach && (
                <PrivateRoute
                  exact
                  path={`/coach/clients`}
                  component={Clients}
                />
              )}
              {user?.isCoach && (
                <PrivateRoute
                  exact
                  path={'/coach/groups'}
                  component={Clients}
                />
              )}
              {(user?.isCoach || (user?.isAdmin && user?.isCoach)) && (
                <PrivateRoute
                  exact
                  path={`/coach/clients/:clientId/session`}
                  component={AddSession}
                />
              )}
              <PrivateRoute
                exact
                path={[
                  '/coach/clients/:clientId/growth-plan/:sowId/:planId',
                  '/admin/users/:clientId/growth-plan/:sowId/:planId',
                ]}
                component={GrowthPlanCall}
              />
              <PrivateRoute
                exact
                path={[
                  '/coach/clients/:clientId/growth-plan/:sowId/:planId/view',
                  '/admin/users/:clientId/growth-plan/:sowId/:planId/view',
                ]}
                component={ViewGrowthPlan}
              />
              {user?.isCoach && (
                <PrivateRoute
                  path={[
                    '/coach/clients/:clientId',
                    '/coach/clients/:clientId/overview',
                    '/coach/clients/:clientId/analytics',
                    '/coach/clients/:clientId/growth-plan',
                    '/coach/clients/:clientId/sessions',
                    `/coach/clients/:clientId/${coachRecommendsUrl}`,
                    '/admin/client/:clientId/view/client-profile',
                    `/coach/clients/:clientId/${simple360Url}`,
                  ]}
                  component={ClientProfile}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute path={`/admin/media`} component={MediaUploader} />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  path={`/admin/upsert/:mediaCategory/:catId/:subCatId/:mediaId`}
                  component={UpsertMedia}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/users`}
                  component={UserList}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute path={`/admin/home`} component={Home} />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  path={[
                    '/admin/organizations',
                    '/admin/organization/:companyName/users',
                    '/admin/organization/:companyName/survey',
                    '/admin/organization/:companyName/coach',
                    '/admin/organization/:companyName/groups',
                    '/admin/organization/:companyName/sows',
                    '/admin/organization/:companyName/invite-link',
                    // '/admin/organization/:companyName/purpose-and-vision',
                    // '/admin/organization/:companyName/values-and-principles',
                    // '/admin/organization/:companyName/objectives',
                    '/admin/organization/:companyName/sow/:sowId/details',
                    '/admin/organization/:companyName/sow/:sowId/users',
                    '/admin/organization/:companyName/sow/:sowId/sow-groups',
                    '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId',
                    '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId/users',
                    '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId/sessions',
                    '/admin/organization/:companyName/sow/:sowId/sow-group/:sowGroupId/coaches',
                    `/admin/organization/:companyName/sow/:sowId/sow-group/:teamId/${coachRecommendsUrl}`,
                  ]}
                  component={Company}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  path={[
                    '/admin/networks',
                    '/admin/network/:ecoSystemName/users',
                    '/admin/network/:ecoSystemName/survey',
                    '/admin/network/:ecoSystemName/invite-link',
                    '/admin/network/:ecoSystemName/welcome-email',
                  ]}
                  component={EcoSystem}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute exact path={'/admin/quests'} component={Quests} />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={[
                    `/admin/quests/create-new`,
                    '/admin/quests/:questId',
                    '/admin/quests/:questId/details',
                    `/admin/quests/:questId/inquiries`,
                    '/admin/quests/:questId/itinerary',
                    `/admin/quests/:questId/participants`,
                    `/admin/quests/:questId/learning`,
                  ]}
                  component={CreateQuest}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute exact path={'/admin/blogs'} component={Blog} />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={[
                    '/admin/blogs/create-new',
                    '/admin/blogs/:blogId/detail',
                  ]}
                  component={BlogDetail}
                />
              )}
              {/* {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={'/admin/blogs/:blogId/detail'}
                  component={EditBlog}
                />
              )} */}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/survey/users_combined_report`}
                  component={UserCombinedSurveyReport}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/survey/organization_comparison_report`}
                  component={CompanyCompareAssessment}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/survey/user_comparison_report_common`}
                  component={() => (
                    <CompanyCompareAssessment apiFor='common-users' />
                  )}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/survey/network_comparison_report`}
                  component={EcoSystemCompareAssessment}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/survey/network_user_comparison_report_common`}
                  component={() => (
                    <EcoSystemCompareAssessment apiFor='common-users' />
                  )}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  path={`/admin/survey/organization/:companyName`}
                  component={CompanyDateSurveyResult}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  path={`/admin/survey/network/:ecoSystemName`}
                  component={EcosystemDateSurveyResult}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute path={`/admin/account`} component={Account} />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  path={`/admin/sitecopy/user`}
                  component={SiteCopy}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  path={`/admin/statistics`}
                  component={PlatformAnalytics}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={[
                    `/admin/groups`,
                    '/admin/groups/active',
                    '/admin/groups/archive',
                  ]}
                  component={TeamListing}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/group`}
                  component={CreateTeam}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/coaches`}
                  component={Coaches}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/coach/:coachId/clients/:clientId/plans/:planId`}
                  component={CoachingPlan}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/coaches/:coachId`}
                  component={CoachClientList}
                />
              )}
              <PrivateRoute
                exact
                path={[
                  '/admin/coach/:coachId/profile',
                  '/coach/:coachId/profile',
                  '/admin/:coachId/profile',
                ]}
                component={CoachProfile}
              />
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={'/admin/profile/:adminId'}
                  component={AdminProfile}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={[
                    '/admin/users/:clientId',
                    '/admin/users/:clientId/overview',
                    '/admin/users/:clientId/analytics',
                    '/admin/users/:clientId/growth-plan',
                    '/admin/users/:clientId/sessions',
                    `/admin/users/:clientId/${coachRecommendsUrl}`,
                    '/admin/client/:clientId/view/client-profile',
                    '/admin/users/:clientId/update-email',
                    `/admin/users/:clientId/${simple360Url}`,
                    '/coach/clients/:clientId/intentions',
                    '/admin/users/:clientId/intentions',
                    '/coach/clients/:clientId/plans-payments',
                    '/admin/users/:clientId/plans-payments',
                    '/coach/clients/:clientId/custom360',
                    '/admin/users/:clientId/custom360',
                  ]}
                  component={ClientProfile}
                />
              )}
              <PrivateRoute
                path='/wisdom/:id/clients/:clientId'
                component={WisdomAssessmentResult}
                exact
              />
              <PrivateRoute
                exact
                path={[
                  '/admin/custom360/users/:clientId/results/recipient',
                  '/coach/custom360/clients/:clientId/results/recipient',
                  '/admin/custom360/users/:clientId/results/question',
                  '/coach/custom360/clients/:clientId/results/question',
                ]}
                component={Custom360Resuls}
              />
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/survey/organization/:companyName`}
                  component={CombinedAwarenessAssessment}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/survey/network/:ecoSystemName`}
                  component={CombinedAwarenessAssessment}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  path={`/survey/organization/view/:companyName`}
                  component={ViewCompanyAssessment}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  path={`/survey/network/view/:ecoSystemName`}
                  component={ViewEcosystemAssessment}
                />
              )}
              {(user?.isAdmin || user?.isCoach) && (
                <PrivateRoute
                  exact
                  path={`/survey/category/:category`}
                  component={SurveyCategory}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/promo`}
                  component={PromoCode}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/promo/:promoId`}
                  component={UpdatePromoCode}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/scheduled-emails`}
                  component={EmailSequencing}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/scheduled-emails/user/email-details`}
                  component={UserEmailsDetails}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/payment`}
                  component={UserPaymentInformation}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={[
                    `/admin/subscription-payments`,
                    `/admin/subscription-payments/sows`,
                    `/admin/subscription-payments/payments`,
                  ]}
                  component={SubScriptionPayment}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/session-insights`}
                  component={SessionInsights}
                />
              )}
              {user?.isAdmin && (
                <PrivateRoute
                  exact
                  path={`/admin/delete-user`}
                  component={DeleteUser}
                />
              )}
              <PrivateRoute
                exact
                path='/survey/leader/:id/clients/:clientId'
                component={LAResult}
              />
              <Redirect to='/' />
            </Switch>
          </Layout>
        </PrivateRoute>
      </MediaControlWrapper>
    </Switch>
  )
}

export default AuthenticatedRoutes
