import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Imgix from 'react-imgix'
import classNames from 'classnames'
import { Row, Col, Form as RBForm } from 'react-bootstrap'
import {
  useResolutionService,
  useErrorService,
  useNotificationService,
} from '@abroad/components'
import API from '../../utils/API'
import SingleDatePickerWithoutFormik from '../form/SingleDatePickerWithoutFormik'
import { CustomTextArea, QuestInputField, QuestSelectField } from '../form'
import {
  currentYearFormat,
  formatDateInDetail,
  getCurrentDate,
} from '../../utils/date'

const QuestOverviewDetail = ({
  questId,
  questDetail,
  questDetails,
  setQuestDetails,
  handleBlur,
}) => {
  const { isMDScreen } = useResolutionService()
  const Error = useErrorService()
  const Notification = useNotificationService()
  const imageRef = useRef(null)
  const [questImageUpload, setQuestImageUpload] = useState(false)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const [selectedStartDate, setSelectedStartDate] = useState(
    formatDateInDetail(questDetail.startDate),
  )
  const currentYear = currentYearFormat()
  const next10Years = Array.from(
    { length: 10 },
    (_, index) => currentYear + index,
  )

  useEffect(() => {
    if (questDetail)
      setSelectedStartDate(formatDateInDetail(questDetail.startDate))
  }, [questDetail])

  const handleQuestDetailsBlur = (fieldName, fieldValue) => {
    setQuestDetails((prevDetails) => ({
      ...prevDetails,
      [fieldName]: fieldValue,
    }))
  }

  const handleStartDateChange = (date) => {
    setQuestDetails((prevDetails) => ({
      ...prevDetails,
      startDate: date,
    }))
    setSelectedStartDate(date)
    handleBlur(null, null, null, null, date, null, null)
  }

  const handleEndDateChange = (date) => {
    setQuestDetails((prevDetails) => ({
      ...prevDetails,
      endDate: date,
    }))
    handleBlur(null, null, null, null, null, date, null)
  }

  const uploadImage = async (imageFile) => {
    try {
      const contentType = imageFile.name
        .substr(imageFile.name.lastIndexOf('.') + 1)
        .toUpperCase()

      if (!questId) {
        Notification.showNotification('Title is Required', 'danger')
        return
      }

      const { data } = await API.AdminAPI.quests.getQuestImage(
        questId,
        contentType,
      )
      if (data) {
        await axios.put(data.url.url, imageFile, {
          headers: {
            'Content-Type': data.url.contentType,
          },
        })

        return data.fileName
      }
    } catch (e) {
      imageRef.current.value = ''
      Error.showError(e)
    }
  }
  const getImage = () => {
    if (questId) {
      imageRef.current.click()
    } else {
      Notification.showNotification('Title is Required', 'danger')
    }
  }

  const handleFileChange = (e) => {
    const files = e.target.files
    if (files && files.length > 0) {
      const imageFile = files[0]
      uploadQuestImage(imageFile)
    }
  }
  const uploadQuestImage = async (imageFile) => {
    try {
      setQuestImageUpload(true)
      const fileName = await uploadImage(imageFile)

      setQuestDetails((prevImage) => {
        const updatedImage = { ...prevImage }
        updatedImage.imageFileName = fileName
        handleBlur('questImage', fileName)
        return updatedImage
      })
    } catch (e) {
    } finally {
      setTimeout(() => {
        setQuestImageUpload(false)
      }, 850)
    }
  }
  return (
    <>
      <Row>
        <Col lg={6}>
          <div>
            {' '}
            <label htmlFor='selectionField' className='s8'>
              NAME
            </label>
            <div className='location-field'>
              <QuestInputField
                type='text'
                name='name'
                value={questDetails.name}
                className='w-100 sa2 selection-field'
                handleBlur={handleQuestDetailsBlur}
              />
            </div>
            <div className='cursor-pointer mt-3'>
              <label className='s8'> HEADER IMAGE(Click to update)</label>
              <div onClick={() => getImage()}>
                {questImageUpload && (
                  <div className={`text-saffron-700 uploading-text-quest`}>
                    Uploading...
                  </div>
                )}
                {questDetails.imageFileName ? (
                  <Imgix
                    imgixParams={{
                      fit: 'crop',
                      crop: 'center',
                      ar: isMDScreen ? '2:1' : '4:2',
                    }}
                    src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questId}/${questDetails.imageFileName}`}
                    // height={193}
                    attributeConfig={{
                      src: 'data-src',
                      srcSet: 'data-srcset',
                      sizes: 'data-sizes',
                    }}
                    htmlAttributes={{
                      src: `${
                        process.env.REACT_APP_IMG_SOURCE
                      }/quest/${questId}/${questDetails.imageFileName}?ar=${
                        isMDScreen ? '2:1' : '4:2'
                      }&fit=crop&crop=center&fill=blur&blur=300&px=16&auto=format`,
                    }}
                    width={'100%'}
                    alt='image'
                    className={classNames(
                      'border-radius-10 questImage-container lazyload',
                      {
                        itenaryImage: questImageUpload,
                      },
                    )}
                  />
                ) : (
                  <Imgix
                    imgixParams={{
                      fit: 'crop',
                      crop: 'format',
                      ar: isMDScreen ? '2:1' : '4:2',
                    }}
                    src={`${process.env.REACT_APP_IMG_SOURCE}/quest-placeholder.png`}
                    width={'100%'}
                    alt='image'
                    htmlAttributes={{
                      src: `${
                        process.env.REACT_APP_IMG_SOURCE
                      }/quest-placeholder.png?ar=${
                        isMDScreen ? '2:1' : '4:2'
                      }&fit=crop&crop=format&fill=blur&blur=300&px=16&auto=format`,
                    }}
                    className={`border-radius-10 w-100 lazyload`}
                  />
                )}

                <RBForm.Control
                  type='file'
                  size='lg'
                  placeholder='Select File'
                  accept='image/jpg, image/jpeg, image/png'
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  name='imageFileName'
                  sm='10'
                  ref={imageRef}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col lg={6} className='d-flex flex-column'>
          <label htmlFor='selectionField' className='s8 mt-md-2 mt-sm-2 mt-2'>
            TITLE
          </label>
          <QuestInputField
            type='text'
            name='title'
            value={questDetails.title}
            className='w-100 sa2 selection-field'
            handleBlur={handleQuestDetailsBlur}
          />

          <Row className='pr-lg-5 pr-md-2 pr-sm-2 sm-2 mt-3'>
            <Col lg={6}>
              <label htmlFor='selectionField' className='s8'>
                MONTH
              </label>
              <QuestSelectField
                label='MONTH'
                name='month'
                value={questDetails.month}
                options={months}
                handleBlur={handleQuestDetailsBlur}
              />
            </Col>
            <Col lg={6} className='d-flex flex-column'>
              <label
                htmlFor='selectionField'
                className='s8 mt-md-2 mt-sm-2 mt-2'>
                YEAR
              </label>
              <QuestSelectField
                label='YEAR'
                name='year'
                value={questDetails.year}
                options={next10Years}
                handleBlur={handleQuestDetailsBlur}
              />
            </Col>

            <Col lg={6} className='mt-3'>
              <label htmlFor='selectionField' className='s8'>
                START DATE
              </label>
              <SingleDatePickerWithoutFormik
                className='sa2 selection-field'
                labelClasses='font-montserrat font-10 font-bold leading-3 tracking-lg-wide'
                onDateApply={handleStartDateChange}
                value={questDetail.startDate}
                isQuest={true}
                minimumDate={getCurrentDate()}
                maximumDate={getCurrentDate().add(10, 'years')}
              />
            </Col>
            <Col lg={6} className='mt-3'>
              <label htmlFor='selectionField' className='s8'>
                END DATE
              </label>
              <SingleDatePickerWithoutFormik
                className='sa2 selection-field'
                labelClasses='font-montserrat font-10 font-bold leading-3 tracking-lg-wide'
                onDateApply={handleEndDateChange}
                value={questDetail.endDate}
                isQuest={true}
                minimumDate={getCurrentDate(selectedStartDate)}
                maximumDate={getCurrentDate().add(10, 'years')}
                key={selectedStartDate}
              />
            </Col>
          </Row>
          <label className='s8 mt-4'>Description</label>
          <CustomTextArea
            name='description'
            value={questDetails.description}
            className='w-100 sa2 selection-field flex-grow-1'
            handleBlur={handleQuestDetailsBlur}
          />
        </Col>
      </Row>
    </>
  )
}
export default QuestOverviewDetail
